import { createBrowserRouter } from "react-router-dom";
import Layout from "@/layouts/Layout";

const router = createBrowserRouter([
  {
    element: <Layout />,
    children: [
      {
        path: "/login",
        lazy: async () => ({
          Component: (await import("@/pages/Login")).default,
        }),
      },
      {
        path: "/agreement",
        lazy: async () => ({
          Component: (await import("@/pages/Docs/Agreement")).default,
        }),
      },
      {
        path: "/policy",
        lazy: async () => ({
          Component: (await import("@/pages/Docs/Policy")).default,
        }),
      },
      {
        path: "/",
        lazy: async () => ({
          Component: (await import("@/layouts/Main")).default,
        }),
        children: [
          {
            index: true,
            lazy: async () => ({
              Component: (await import("@/pages/Home")).default,
            }),
          },
          {
            path: "/market",
            lazy: async () => ({
              Component: (await import("@/pages/Market")).default,
            }),
          },
          {
            path: "/activity/:type?",
            lazy: async () => ({
              Component: (await import("@/pages/Activity")).default,
            }),
          },
          {
            path: "/notice",
            lazy: async () => ({
              Component: (await import("@/pages/Notice")).default,
            }),
          },
          {
            path: "/mine",
            lazy: async () => ({
              Component: (await import("@/pages/Mine")).default,
            }),
          },
          {
            path: "/order",
            lazy: async () => ({
              Component: (await import("@/pages/Order")).default,
            }),
          },
          {
            path: "/order/detail/:no",
            lazy: async () => ({
              Component: (await import("@/pages/Order/Detail")).default,
            }),
          },
          {
            path: "/asset/detail/:id",
            lazy: async () => ({
              Component: (await import("@/pages/Asset")).default,
            }),
          },
          {
            path: "/market/detail/:id",
            lazy: async () => ({
              Component: (await import("@/pages/Market/Detail")).default,
            }),
          },
          {
            path: "/activity/detail/:id",
            lazy: async () => ({
              Component: (await import("@/pages/Activity/Detail")).default,
            }),
          },
          {
            path: "/lottery/detail/:id",
            lazy: async () => ({
              Component: (await import("@/pages/Lottery")).default,
            }),
          },
          {
            path: "/setting/detail",
            lazy: async () => ({
              Component: (await import("@/pages/Setting")).default,
            }),
          },
          {
            path: "/invite/detail",
            lazy: async () => ({
              Component: (await import("@/pages/Invite")).default,
            }),
          },
          {
            path: "/invite/detail/list",
            lazy: async () => ({
              Component: (await import("@/pages/Invite/List")).default,
            }),
          },
          {
            path: "/wallet/detail",
            lazy: async () => ({
              Component: (await import("@/pages/Wallet")).default,
            }),
          },
          {
            path: "/plan/detail/:id",
            lazy: async () => ({
              Component: (await import("@/pages/Plan")).default,
            }),
          },
          {
            path: "/sale/detail/:id",
            lazy: async () => ({
              Component: (await import("@/pages/Sale")).default,
            }),
          },
          {
            path: "/notice/detail/:id",
            lazy: async () => ({
              Component: (await import("@/pages/Notice/Detail")).default,
            }),
          },
          {
            path: "/setting/auth",
            lazy: async () => ({
              Component: (await import("@/pages/Setting/Auth")).default,
            }),
          },
          {
            path: "/setting/security",
            lazy: async () => ({
              Component: (await import("@/pages/Setting/Security")).default,
            }),
          },
        ],
      },
    ],
  },
]);

export default router;
