import { useReactive } from "ahooks";
import { useEffect } from "react";
import { useOutlet } from "react-router-dom";
import styles from "./index.module.css";

export default () => {
  const outlet = useOutlet();

  const state = useReactive({
    isApp: false,
  });

  useEffect(() => {
    const params = new URLSearchParams(new URL(window.location.href).search);
    const code = params.get("code");
    const app = params.get("app");

    if (!!code) {
      localStorage.setItem("code", decodeURIComponent(code));
    }
    if (!!app) {
      localStorage.setItem("app", app);
    }

    state.isApp = !!localStorage.getItem("app");
  }, []);

  return <div className={state.isApp ? styles.app : ""}>{outlet}</div>;
};
