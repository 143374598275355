import "@vant/touch-emulator";
import { createRoot } from "react-dom/client";
import { RouterProvider } from "react-router-dom";
import { ConfigProvider } from "react-vant";
import "./index.css";
import router from "./router";

const container = document.querySelector("#root")!;
const root = createRoot(container);

root.render(
  <ConfigProvider>
    <RouterProvider router={router} />
  </ConfigProvider>
);
